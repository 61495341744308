@import url('https://use.typekit.net/sys0fpu.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .tw-widget-root *,
  .tw-widget-root ::before,
  .tw-widget-root ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.DEFAULT');
  }

  .tw-widget-root ::before,
  .tw-widget-root ::after {
    --tw-content: '';
  }

  .tw-widget-root {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-family: theme(
      'fontFamily.sans',
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji'
    );
    font-feature-settings: theme('fontFamily.sans[1].fontFeatureSettings', normal);
    @apply tw-tracking-normal
      tw-font-normal
      tw-text-left
      tw-text-ui-black;
  }

  .tw-widget-root hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  .tw-widget-root abbr:where([title]) {
    text-decoration: underline dotted;
  }

  .tw-widget-root h1,
  .tw-widget-root h2,
  .tw-widget-root h3,
  .tw-widget-root h4,
  .tw-widget-root h5,
  .tw-widget-root h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  .tw-widget-root a {
    color: inherit;
    text-decoration: inherit;
  }

  .tw-widget-root b,
  .tw-widget-root strong {
    font-weight: bolder;
  }

  .tw-widget-root small {
    font-size: 80%;
  }

  .tw-widget-root sub,
  .tw-widget-root sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  .tw-widget-root sub {
    bottom: -0.25em;
  }

  .tw-widget-root sup {
    top: -0.5em;
  }

  .tw-widget-root button,
  .tw-widget-root input,
  .tw-widget-root optgroup,
  .tw-widget-root select,
  .tw-widget-root textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  .tw-widget-root button,
  .tw-widget-root select {
    text-transform: none;
  }

  .tw-widget-root button,
  .tw-widget-root [type='button'],
  .tw-widget-root [type='reset'],
  .tw-widget-root [type='submit'] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }

  .tw-widget-root :-moz-focusring {
    outline: auto;
  }

  .tw-widget-root :-moz-ui-invalid {
    box-shadow: none;
  }

  .tw-widget-root ::-webkit-inner-spin-button,
  .tw-widget-root ::-webkit-outer-spin-button {
    height: auto;
  }

  .tw-widget-root ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  .tw-widget-root summary {
    display: list-item;
  }

  .tw-widget-root blockquote,
  .tw-widget-root dl,
  .tw-widget-root dd,
  .tw-widget-root h1,
  .tw-widget-root h2,
  .tw-widget-root h3,
  .tw-widget-root h4,
  .tw-widget-root h5,
  .tw-widget-root h6,
  .tw-widget-root hr,
  .tw-widget-root figure,
  .tw-widget-root p,
  .tw-widget-root pre {
    margin: 0;
  }

  .tw-widget-root fieldset {
    margin: 0;
    padding: 0;
  }

  .tw-widget-root legend {
    padding: 0;
  }

  .tw-widget-root ol,
  .tw-widget-root ul,
  .tw-widget-root menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .tw-widget-root textarea {
    resize: vertical;
  }

  .tw-widget-root input::placeholder,
  .tw-widget-root textarea::placeholder {
    opacity: 1; /* 1 */
    color: theme('colors.gray.400', #9ca3af);
  }

  .tw-widget-root button,
  .tw-widget-root [role='button'] {
    cursor: pointer;
  }

  .tw-widget-root :disabled {
    cursor: default;
  }

  .tw-widget-root img,
  .tw-widget-root svg,
  .tw-widget-root video,
  .tw-widget-root canvas,
  .tw-widget-root audio,
  .tw-widget-root iframe,
  .tw-widget-root embed,
  .tw-widget-root object {
    display: block;
    vertical-align: middle;
  }

  .tw-widget-root img,
  .tw-widget-root video {
    max-width: 100%;
    height: auto;
  }

  .tw-widget-root [hidden] {
    display: none;
  }
}

@layer components {
  .tw-wrapper {
    @apply tw-container tw-mx-auto tw-mb-24;
  }

  .tw-spinner {
    font-size: 100px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
  }
  .tw-spinner .tw-spinner-blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 0.094em;
    height: 0.2777em;
    border-radius: 0.0555em;
    background-color: transparent;
    -webkit-transform-origin: center -0.2222em;
    -ms-transform-origin: center -0.2222em;
    transform-origin: center -0.2222em;
    -webkit-animation: tw-spinner-fade 1s infinite linear;
    animation: tw-spinner-fade 1s infinite linear;
  }
  .tw-spinner .tw-spinner-blade:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(2) {
    -webkit-animation-delay: 0.083s;
    animation-delay: 0.083s;
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(3) {
    -webkit-animation-delay: 0.166s;
    animation-delay: 0.166s;
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(4) {
    -webkit-animation-delay: 0.249s;
    animation-delay: 0.249s;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(5) {
    -webkit-animation-delay: 0.332s;
    animation-delay: 0.332s;
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(6) {
    -webkit-animation-delay: 0.415s;
    animation-delay: 0.415s;
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(7) {
    -webkit-animation-delay: 0.498s;
    animation-delay: 0.498s;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(8) {
    -webkit-animation-delay: 0.581s;
    animation-delay: 0.581s;
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(9) {
    -webkit-animation-delay: 0.664s;
    animation-delay: 0.664s;
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(10) {
    -webkit-animation-delay: 0.747s;
    animation-delay: 0.747s;
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(11) {
    -webkit-animation-delay: 0.83s;
    animation-delay: 0.83s;
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .tw-spinner .tw-spinner-blade:nth-child(12) {
    -webkit-animation-delay: 0.913s;
    animation-delay: 0.913s;
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }

  @-webkit-keyframes tw-spinner-fade {
    0% {
      background-color: theme('colors.ui-black');
    }
    100% {
      background-color: theme('colors.transparent');
    }
  }

  @keyframes tw-spinner-fade {
    0% {
      background-color: theme('colors.ui-black');
    }
    100% {
      background-color: theme('colors.transparent');
    }
  }

  @media screen(sm) {
    .tw-spinner {
      font-size: 140px;
    }
  }

  a.tw-free-download-btn {
    @apply tw-bg-ui-blue tw-text-ui-white tw-text-lg tw-font-normal tw-shrink-0 tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-3;
    max-width: 360px;
    min-width: 260px;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 24px;
    padding: 11px 20px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  a.tw-free-download-btn:hover {
    background-color: #498ee6;
    border-color: #498ee6;
  }

  .dropzone-start-par a {
    @apply tw-text-ui-blue tw-font-bold tw-underline hover:tw-no-underline;
  }
}
